<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：发票开票一览</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :lg="8" :sm="12" :md="9">
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="searchInvoice" size="mini">搜索
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/invoiceAll'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openBill($refs.xGrid.getCurrentRecord())" size="mini">修改发票
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/invoiceAll'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openBill($refs.xGrid.getCurrentRecord())" size="mini">修改销售单
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button type="success" v-if="$_getActionKeyListBoolean(['/invoice'])" style=" margin-top: 8px; " round
                                           @click="$router.push('/invoice')" size="mini">新建
                                </el-button>
                            </el-col>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">

                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>

                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGrid"
                                ref="xGrid"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-current-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :custom-config="{storage:true}"
                                show-footer
                                :mouse-config="{selected: false}"
                                :footer-method="footerMethod"
                                :row-class-name="tableRowClassName"
                                :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                type:'',//空代表默认，CN代表出纳用的（外购单一览）
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {field:'invoice_id', title: '序号', width: 50},
                    {
                        field:'clearing_form', width: 80, title: '结算方式', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill', width: 65, title: '税率', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'bill_date_invoice', width: 100, title: '发票日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'only_bill_date', width: 100, title: '销售日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'customer', width: 240, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'bill_id_kd', width: 120, title: '销售开单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'remarkInvoice', width: 120, title: '开票备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_amount', width: 80, title: '合计金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_number', width: 80, title: '合计支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'all_fees', width: 80, title: '合计杂费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'total_weighing_heavy', width: 80, title: '总过磅重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_theoretical_weight', width: 80, title: '总理论重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'practical_total_price', width: 80, title: '合计实收', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'kick_back_total', width: 88, title: '合计佣金', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'part_order', width: 80, title: '开单员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'print_count', width: 60, title: '打印', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'handled_by', width: 80, title: '经手人',editRender: {autofocus: 'input.el-input__inner'}, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'last_name_warehouse_bill', width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'last_date_warehouse_bill', width: 120, title: '最后修改时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'creat_date_warehouse_bill', width: 120, title: '记录日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_amount', width: 80, title: '合计金额',visible : false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                    {field:'b_id', width: 160,visible : false,title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }},
                    {
                        field:'remark_warehouse_bill',visible : false, width: 80, title: '订单备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'remark_stock',visible : false, width: 80, title: '产品备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoiceAll/searchHeard', this.pickerDate)
                       }
                   },
                ],
                tableDataMain: [],
                tableDataModel : [],
                tableColumnModel: [
                    {field:'is_cancel_name', title: '状态', width: 80},
                    {field:'cash_type_id', title: '单据编码', width: 80},
                    {field:'cash_type_name',width: 122 , title: '单据类型'},
                    {field:'year',width: 40 , title: '年'},
                    {field:'month',width: 40 , title: '月'},
                    {field:'amount',width: 150 , title: '金额'},
                    {field:'registrar_cash_data',width: 150 , title: '创建人'},
                    {field:'creat_date_cash_data',width: 150 , title: '创建日期'},
                    {field:'last_name_cash_data',width: 150 , title: '最后修改人'},
                    {field:'last_date_cash_data',width: 150 , title: '最后修改日期'},
                ],
                filterData: {
                    "bill_id_type": "KD"
               },
                sortData: {"creat_date_warehouse_bill": "desc"},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                activeName: 'first',

           }
       },
        methods: {
            openReceipt(row){
                this.$router.push({path: '/receipt', query: {cash_type_id: row.cash_type_id,year:row.year,month:row.month,type:row.cash_type}})
           },

            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return Number(obj)
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'practical_price' || column.property == 'amount_of_product' || column.property == 'total_amount' || column.property == 'total_number'
                            || column.property == 'all_fees' || column.property == 'total_weighing_heavy' || column.property == 'total_theoretical_weight'|| column.property == 'practical_total_price'
                            || column.property == 'kick_back_total') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

            searchInvoice() {
                this.tablePage.currentPage = 1;
                this.getInvoice();
           },
            getInvoice() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/invoiceAll/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getInvoice();
           },
            openBill(row) {
                this.$router.push({path: '/invoice', query: {id: row.invoice_id}})
           },
            openInvoiceAll(row) {
                this.$router.push({path: '/invoice', query: {id: row.invoice_id}})
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },

       },computed: {
       },
        created() {
            if (!this.isBlankVue(this.$route.query.type)){
                this.type = this.$route.query.type;
            }
            this.$_getAccountListByRoleId('16');//经手人
            this.getInvoice();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }

</style>
